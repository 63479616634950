import axios from 'axios'

export const AdminAPI = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
    headers: {
        Authorization: sessionStorage?.getItem("token"),
    },
});


 AdminAPI.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if(error?.response?.status === 401) {
            window.location.href = "/";
        }
        return error.response;
    }
);