import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { DeleteBank, getAllBanks } from "../redux/actions/AdminActions";
import { toast } from "react-toastify";
import Pagination from "../Components/Pagination";
import Logo from "../Assets/Images/Profile-icon.png";

export default function BankAccount() {
  const Url = process.env.REACT_APP_FILE_BASE_URL;
  const isAdmin = sessionStorage.getItem("is_admin");
  const isAdminBool = isAdmin === "1";

  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const [page, setpage] = useState(1);

  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [Banklist, setBankssList] = useState([]);
  const [pagi, setpagi] = useState("");
  const [showd, setshowd] = useState(false);
  const [bankId, setBankid] = useState("");
  const [modalData, setModalData] = useState();
  console.log(modalData, "dataaaa");

  const dispatch = useDispatch();

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };

    dispatch(getAllBanks(searchData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setBankssList(res?.payload?.data?.data?.totalBanks);
          setpagi(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err, "error occur");
      });
  }, [page, limit, search, flag]);

  const handleCopyInfo = () => {
    const info = `Benificiary Name : ${modalData?.beneficiary_name}\n Bank Name : ${modalData?.bank_name}\nAccount Branch Address : ${modalData?.branch_address}\nIFSC Code : ${modalData?.IFSC_code}\nSwift Code : ${modalData?.swift_code}\nIBAN Number : ${modalData?.IBAN_number}`;
    navigator.clipboard
      .writeText(info)
      .then(() => {
        setshow(false);
        toast.success("Info Copied Sucessfully");
        setTimeout(() => {
          toast.dismiss();
        }, 1500);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleProfile = (id) => {
    if (id) {
      navigate(`/edit-bank/${id}`);
    } else {
      navigate(`/Addbankdetails`);
    }
  };

  const handleDelete = () => {
    dispatch(DeleteBank({ bankId: bankId }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>BankAccount</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search "
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                {isAdminBool &&   <Link to="/Addbankdetails">Add NEW ACCOUNT</Link>}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        {/* <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Notification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container> */}
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pagi?.currentPage - 1) * limit} -{" "}
                {Math.min(pagi?.currentPage * limit, pagi?.totalCount)} of{" "}
                {pagi?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Company Logo</th>
                <th>Bank Name</th>
                <th>Beneficiary name</th>
                <th>Account Number</th>
                <th>Action</th>
                {isAdminBool && <th>Edit</th>}
                {isAdminBool && <th>Delete</th>}
              </tr>
            </thead>
            <tbody>
              {Banklist?.map((res, index) => {
                console.log(res);
                const serialNumber =
                  ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td className="user-img">
                      <img
                        className="img-fluid"
                        src={
                          res?.company_id?.company_logo
                            ? `${Url}${res?.company_id?.company_logo}`
                            : Logo
                        }
                      />
                    </td>
                    <td>{res?.bank_name || "N/A"}</td>
                    <td>{res?.beneficiary_name || "N/A"}</td>
                    <td>{res?.mask_number || "N/A"}</td>
                    <td>
                      {" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          setshow(true);
                          setModalData(res);
                        }}
                      >
                        Copy Info.
                      </Button>
                    </td>
                    {isAdminBool && (
                      <td>
                        <button
                          className="icon-button edit-button"
                          aria-label="Edit"
                          onClick={() => handleProfile(res?._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </button>
                      </td>
                    )}

                    {isAdminBool && (
                      <td>
                        <button
                          className="icon-button delete-button"
                          aria-label="Delete"
                          onClick={() => {
                            setshowd(true);
                            setBankid(res?._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Header closeButton onHide={() => setshow(false)}></Modal.Header>
        <Modal.Body className="text-center">
          {/* <h2 className="are-sure-text">
            Info
          </h2>
          <p>CODOBUX IT SERVICES PRIVATE LIMITED</p> */}
          <div className="info-list" style={{ textAlign: "initial" }}>
            <p> Benificiary Name : {modalData?.beneficiary_name} </p>
            <p> Bank Name : {modalData?.bank_name} </p>
            <p> Account Branch Address : {modalData?.branch_address}</p>
            <p> IFSC Code : {modalData?.IFSC_code}</p>
            <p> Swift Code : {modalData?.swift_code}</p>
            <p> IBAN Number : {modalData?.IBAN_number}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleCopyInfo}>
            Copy Information
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to remove this bank
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpage}
        />
      </div>
    </Layout>
  );
}
