import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  UpdateBank,
  addBank,
  getBankDetails,
  getcompny,
} from "../redux/actions/AdminActions";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  bank_name: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  beneficiary_name: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  account_number: Yup.string()
    .matches(/^[0-9]+$/, {
      message: <span className="error-message">Account number must be numbers</span>,
      excludeEmptyString: true,
    })
    .required(<span className="error-message">Required</span>),
  IFSC_code: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  IBAN_number: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  branch_address: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  swift_code: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  company_name: Yup.string().required(
    <span className="error-message">Required</span>
  ),
});

export default function AddBankDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [load, setLoad] = useState(true);
  const [DropdowncompanyList, setDropdowncompanyList] = useState([]);

  const [initialvalues, setInitialValues] = useState({
    bank_name: "",
    beneficiary_name: "",
    account_number: "",
    IFSC_code: "",
    IBAN_number: "",
    branch_address: "",
    swift_code: "",
    company_name: "",
  });

  const fetchBankdetails = async () => {
    try {
      const response = await dispatch(getBankDetails(id));
      const bank = response.payload.data.data;
      console.log(bank, "bank");
      setInitialValues({
        bank_name: bank?.bank_name,
        beneficiary_name: bank?.beneficiary_name,
        account_number: bank?.account_number,
        IFSC_code: bank?.IFSC_code,
        IBAN_number: bank?.IBAN_number,
        branch_address: bank?.branch_address,
        swift_code: bank?.swift_code,
        company_name: bank?.company_id?._id,
      });
      // Form.setFieldValue("cinNumber", "1232");
      console.log(bank?.company_id?.company_name);
    } catch (error) {
      console.log("Error fetching company details:", error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBankdetails();
    } else {
      setLoad(false);
    }
  }, [id]);

  useEffect(() => {
    dispatch(getcompny())
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setDropdowncompanyList(res?.payload?.data?.data?.totalCompanies);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handlebanksubmit = async (values) => {
    console.log(values, "values");

    try {
      let data;
      const payload = {
        company_id: values?.company_name,
        bank_name: values?.bank_name,
        beneficiary_name: values?.beneficiary_name,
        account_number: values?.account_number,
        IFSC_code: values?.IFSC_code,
        IBAN_number: values?.IBAN_number,
        branch_address: values?.branch_address,
        swift_code: values?.swift_code,
      };
      if (id) {
        data = await dispatch(
          UpdateBank({
            bankId: id,
            company_id: values?.company_name,
            bank_name: values?.bank_name,
            beneficiary_name: values?.beneficiary_name,
            account_number: values?.account_number,
            IFSC_code: values?.IFSC_code,
            IBAN_number: values?.IBAN_number,
            branch_address: values?.branch_address,
            swift_code: values?.swift_code,
          })
        );
      } else {
        data = await dispatch(addBank(payload));
      }
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        navigate("/bankAccount");
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(DropdowncompanyList, "DropdowncompanyList");

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>{id ? "Edit Bank Details" : "Add Bank Details"}</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/bankAccount">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Col lg={8}>
            <div className="customer-form-new">
              <div className="customer-form-inner">
                {!load && (
                  <Formik
                    initialValues={initialvalues}
                    validationSchema={validationSchema}
                    onSubmit={handlebanksubmit}
                  >
                    {({ values, handleChange, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <div className="admin-box-select">
                              <Form.Group className="mb-3">
                                <Form.Label>Bank Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type..."
                                  name="bank_name"
                                  onChange={handleChange}
                                  value={values?.bank_name}
                                />
                                <ErrorMessage
                                  name="bank_name"
                                  component="div"
                                />
                              </Form.Group>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Beneficiary Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type..."
                                name="beneficiary_name"
                                onChange={handleChange}
                                value={values?.beneficiary_name}
                              />
                              <ErrorMessage
                                name="beneficiary_name"
                                component="div"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Account Number</Form.Label>
                              <Form.Control
                                type=""
                                placeholder="Type..."
                                name="account_number"
                                onChange={handleChange}
                                value={values?.account_number}
                              />
                              <ErrorMessage
                                name="account_number"
                                component="div"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>IFSC Code</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type..."
                                name="IFSC_code"
                                onChange={handleChange}
                                value={values?.IFSC_code}
                              />
                              <ErrorMessage name="IFSC_code" component="div" />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Swift Code</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type..."
                                name="swift_code"
                                onChange={handleChange}
                                value={values?.swift_code}
                              />
                              <ErrorMessage name="swift_code" component="div" />
                            </Form.Group>
                          </Col>

                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>IBAN Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type..."
                                name="IBAN_number"
                                onChange={handleChange}
                                value={values?.IBAN_number}
                              />
                              <ErrorMessage
                                name="IBAN_number"
                                component="div"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Branch Address</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type..."
                                name="branch_address"
                                onChange={handleChange}
                                value={values?.branch_address}
                              />
                              <ErrorMessage
                                name="branch_address"
                                component="div"
                              />
                            </Form.Group>
                          </Col>

                          <Form.Group className="mb-3">
                            <Form.Label>Company</Form.Label>
                            <Form.Select
                              className="form-select"
                              aria-label="Default select example"
                              name="company_name"
                              onChange={handleChange}
                              value={values?.company_name}
                            >
                              <option>Select</option>
                              {DropdowncompanyList?.map((data, i) => {
                                return (
                                  <option value={data?._id}>
                                    {data?.company_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <ErrorMessage name="company_name" component="div" />
                          </Form.Group>

                          <Col
                            lg={12}
                            className="d-flex justify-content-center mt-3"
                          >
                            <button type="submit" className="add-btn">
                              {id ? "Update" : "Add"}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
