import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export default function ResetPassword() {

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default}/>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Enter New Password</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Enter Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">
                       
                      </Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confrmPass"
                    
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">
                       
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div

                    variant="primary"
                    type="button"
                    className="submit"
                    >    
                    Log In
                    </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
