import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import {
  AnalyticsIcon,
  BankAccountIcon,
  BudgetIcon,
  ChnagePassIcon,
  CompanyIcon,
  DashboardIcon,
  LogoutIcon,
  TransactionIcon,
  UsersIcon,
} from "../Sidebarsvg";



export default function Sidebar({ isActive, setIsActive, setShow, show }) {
  const isAdmin = sessionStorage.getItem("is_admin");
  const isAdminBool = isAdmin === "1";
  const [showd, setshowd] = useState(false);
 

  const adminMenuItems = [
    { path: "/Dashboard", label: "Dashboard", icon: <DashboardIcon/> },
    { path: "/Analytics", label: "Analytics", icon: <AnalyticsIcon/> },
    { path: "/users", label: "Users", icon: <UsersIcon /> },
    { path: "/Transaction", label: "Transaction", icon: <TransactionIcon /> },
    { path: "/Company", label: "Company", icon: <CompanyIcon /> },
    { path: "/bankAccount", label: "BankAccount", icon: <BankAccountIcon /> },
    { path: "/budget", label: "Budget", icon: <BudgetIcon /> },
    {
      path: "/ChangePassword",
      label: "Chnage Password",
      icon: <ChnagePassIcon />,
    },
    { path: "/", label: "Logout", icon: <LogoutIcon /> },
  ];

  const nonAdminMenu = [
    { path: "/Transaction", label: "Transaction", icon: <TransactionIcon /> },
    { path: "/Company", label: "Company", icon: <CompanyIcon /> },
    { path: "/bankAccount", label: "BankAccount", icon: <BankAccountIcon /> },
    { path: "/budget", label: "Budget", icon: <BudgetIcon /> },
    { path: "/", label: "Logout", icon: <LogoutIcon /> },
  ];
  console.log(nonAdminMenu,"non");

  const menuItems = isAdminBool ? adminMenuItems : nonAdminMenu;




  const handleClick = (path, label) => {
    if (label === "Logout") {
      setshowd(true); 
    } else {
      window.location.href = path; 
    }
  };


    const handleLogout = () => {
    sessionStorage.removeItem("is_admin"); 
    setshowd(false); 
    window.location.href = "/"; 
  };
   


  return (
    <aside>
      <div className="sidebar">
        <div className="toggle-icon" onClick={() => setShow(!show)}>
          <img src={require("../../Assets/Images/toggle.svg").default} />
        </div>
        <div className="logo-side">
          <img
            className="small"
            src={require("../../Assets/Images/C.svg").default}
          />
          <img
            className="big"
            src={require("../../Assets/Images/Logo.svg").default}
          />
        </div>
        <div className="side-menu">
          <div>
            {menuItems.map(({ path, label, icon }) => (
              <div key={path} className="side-btm-space">
                <Link
                  className={
                    window.location.pathname == path 
                      ? "menu-btn bar_active"
                      : "menu-btn"
                  }
                  to={path}
                  onClick={(e) => {
                    e.preventDefault(); 
                    handleClick(path, label); 
                  }}
                >
                  {icon}
                  <p>{label}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">Are you sure you want to Logout ?</h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleLogout}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </aside>
  );
}
