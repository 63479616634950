import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import {
  DeleteUser,
  UpdateUserPassword,
  getAllUsers,
} from "../redux/actions/AdminActions";
import { useDispatch } from "react-redux";
import Pagination from "../Components/Pagination";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

export default function AllUsers() {
  const isAdmin = sessionStorage.getItem("is_admin");
  const isAdminBool = isAdmin === "1";
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [pagi, setpagi] = useState("");
  const [showd, setshowd] = useState(false);
  const [Userid, setuserid] = useState("");
  const [showChangepass, setshowChangePass] = useState(false);
  const [chnagePassword, setChangePassword] = useState("");
  const [chnagePasswordErr, setChangePasswordErr] = useState("");
  const [Passwordid, setPasswordId] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  const handlePasswordChange = (e) => {
    setChangePassword(e.target.value);

    // Clear the error message if the input is not empty
    if (e.target.value.trim() !== "") {
      setChangePasswordErr("");
    }
  };

  const Updatepassword = () => {
    if (chnagePassword === "") {
      setChangePasswordErr("Required");
      return;
    }
    
    setChangePasswordErr("");

    dispatch(UpdateUserPassword({userId:Passwordid,password:chnagePassword}))
      .then((res) => {
        console.log(res);
        if(res?.payload?.data?.status === 200){
          toast.success(res?.payload?.data?.message)
          setshowChangePass(false)
          setChangePassword("")
        }
      })
      .catch((error) => {
        console.log(error, "error Occur");
      });
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };

    dispatch(getAllUsers(searchData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setUsersData(res?.payload?.data?.data?.totalUsers);
          setpagi(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err, "error occur");
      });
  }, [page, limit, search, flag]);

  const handleDeleteUser = () => {
    dispatch(DeleteUser({ userId: Userid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const handleProfile = (id) => {
    if (id) {
      navigate(`/edit-user/${id}`);
    } else {
      navigate(`/add-user`);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Users</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    name="search "
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  {isAdminBool && <Link to="/add-user">Add User</Link>}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement">ALL</Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (pagi?.currentPage - 1) * limit} -{" "}
                {Math.min(pagi?.currentPage * limit, pagi?.totalCount)} of{" "}
                {pagi?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>First Name</th>

                <th>Email</th>
                <th>Permission</th>
                {isAdminBool && <th>Edit</th>}
                {isAdminBool && <th>Delete</th>}
                <th>Change Password</th>
              </tr>
            </thead>
            <tbody>
              {usersData?.map((res, index) => {
                console.log(res);
                const serialNumber =
                  ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <Link>{res?.full_name || "N/A"}</Link>
                    </td>
                    <td>{res?.email}</td>
                    <td>{res?.is_admin === 1 ? "Super Admin" : "User"}</td>

                    {isAdminBool && (
                      <td>
                        <button
                          className="icon-button edit-button"
                          aria-label="Edit"
                          onClick={() => handleProfile(res?._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </button>
                      </td>
                    )}

                    {isAdminBool && (
                      <td>
                        <button
                          className="icon-button delete-button"
                          aria-label="Delete"
                          onClick={() => {
                            setshowd(true);
                            setuserid(res?._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        </button>
                      </td>
                    )}
                    <td>
                      <button
                        className="chnage-paas-button"
                        aria-label="Change-Password"
                        onClick={() => {
                          setshowChangePass(true);
                          setPasswordId(res?._id);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-shield"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.338 1.59a61 61 0 0 0-2.837.856.48.48 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 0 0 2.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 0 0 .101.025 1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to remove this user
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteUser}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showChangepass}
        animation={false}
        className="change-password-popup"
      >
        <Modal.Body className="text-center">
          <h2 className="change-password-text">Change Password</h2>

          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Enter new password"
              value={chnagePassword}
              onChange={handlePasswordChange}
            />
          </Form.Group>
          <div style={{ textAlign: "start" }}>
            {chnagePasswordErr && (
              <span className="error-message">{chnagePasswordErr}</span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={Updatepassword}>
            Change
          </Button>
          <Button
            className="theme-btn"
            onClick={() => setshowChangePass(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpage}
        />
      </div>
    </Layout>
  );
}
