import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/AdminAuth";
import { toast } from "react-toastify";


const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(<span className="error-message">Invalid Email Address</span>)
    .required(<span className="error-message">Required</span>),
  password: Yup.string()
    .min(6, <span className="error-message">Password too short</span>)
    .required(<span className="error-message">Required</span>),
});

export default function Login() {
  const dispatch = useDispatch();

  const handleLoginSubmit = async(values) => {
       try{
         const data = await dispatch(login(values));
         console.log(data,"andar");
         if (data?.payload?.status === 200) {
          toast.success(data?.payload?.message);
          setTimeout(() => {
            toast.dismiss();
            if(data?.payload?.data?.is_admin === 1){
              window.location.href = ('/Dashboard')
            }else{
              window.location.href = ('/Transaction')
            }
          }, 1500);
        } else {
          toast.error(data?.payload?.message);
        }
       }catch(error){
        console.log(error);
       }
  }


  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Log in</h2>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={LoginSchema}
                  onSubmit={handleLoginSubmit}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-set">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Type Here"
                            name="email"
                            onChange={handleChange}
                            value={values?.email}
                          />
                          <ErrorMessage name="email" component="div"/>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Type Here"
                            name="password"
                            value={values?.password}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="password" component="div"/>
                        </Form.Group>
                      </div>
                      <button
                        variant="primary"
                        type="submit"
                        className="submit"
                      >
                        Log In
                      </button>
                      <Link to="/Forgot-Password" className="forgot">
                        Forgot Password?
                      </Link>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
