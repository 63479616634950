import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  CompanyFilter,
  DeleteBudget,
  getBudget,
  getcompny,
} from "../redux/actions/AdminActions";
import { toast } from "react-toastify";
import Pagination from "../Components/Pagination";
import { Modal } from "react-bootstrap";
import Logo from "../Assets/Images/Profile-icon.png";

export default function Budget() {
  const Url = process.env.REACT_APP_FILE_BASE_URL;
  const isAdmin = sessionStorage.getItem("is_admin");
  const isAdminBool = isAdmin === "1";
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const [page, setpage] = useState(1);

  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [budgetlist, setBudgetlist] = useState([]);
  const [pagi, setpagi] = useState("");
  const [showd, setshowd] = useState(false);
  const [budgetid, setBudgetid] = useState("");
  const [pagetype, setpageType] = useState("");
  const [DropValue, setDropValue] = useState("");
  console.log(DropValue);
  const [DropdowncompanyList, setDropdowncompanyList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
      type: pagetype,
      company_id: DropValue,
    };

    dispatch(getBudget(searchData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setBudgetlist(res?.payload?.data?.data?.totalBudgetList);
          setpagi(res?.payload?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err, "error occur");
      });
  }, [page, limit, search, pagetype, flag, DropValue]);

  const handleProfile = (id) => {
    if (id) {
      navigate(`/edit-budget/${id}`);
    } else {
      navigate(`/addbudget`);
    }
  };

  useEffect(() => {
    dispatch(CompanyFilter())
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setDropdowncompanyList(res?.payload?.data?.data?.totalCompanies);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [DropValue]);

  const handleDelete = () => {
    dispatch(DeleteBudget({ budgetId: budgetid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Budget</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setpageType(e.target.value)}
                >
                  {/* <option>Select</option> */}
                  <option value="">All</option>
                  <option value="0">Income</option>
                  <option value="1">expense</option>
                </Form.Select>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setDropValue(e.target.value)}
                >
                  <option value="">All</option>
                  {DropdowncompanyList?.map((res) => {
                    return (
                      <>
                        <option value={res?._id}>{res?.company_name}</option>
                      </>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                {isAdminBool &&  <Link to="/addbudget">Add Budget</Link> }
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        {/* <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/budget" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container> */}
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pagi?.currentPage - 1) * limit} -{" "}
                {Math.min(pagi?.currentPage * limit, pagi?.totalCount)} of{" "}
                {pagi?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Company Logo</th>
                <th>Budget Name</th>
                <th>Budget Type</th>
                <th>Comments</th>
                {isAdminBool && <th>Edit</th>}
                {isAdminBool && <th>Delete</th>}
              </tr>
            </thead>
            <tbody>
              {budgetlist?.map((res, index) => {
                const serialNumber =
                  ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <div className="user-img">
                        <img
                          className="img-fluid"
                          src={
                            res?.company_id?.company_logo
                              ? `${Url}${res?.company_id?.company_logo}`
                              : Logo
                          }
                        />
                      </div>
                    </td>
                    <td>{res?.budget_name || "N/A"}</td>
                    <td>{res?.budget_type === 0 ? "Income" : "Expense"}</td>
                    <td>{res?.comments || "N/A"}</td>
                    {isAdminBool && (
                      <td>
                        <button
                          className="icon-button edit-button"
                          aria-label="Edit"
                          onClick={() => handleProfile(res?._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </button>
                      </td>
                    )}
                    {isAdminBool && (
                      <td>
                        <button
                          className="icon-button delete-button"
                          aria-label="Delete"
                          onClick={() => {
                            setshowd(true);
                            setBudgetid(res?._id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        </button>
                      </td>
                    )}

                    {/* <td>
                <div className="select-box">
                  <Form.Select aria-label="Default select example">
                    <option>Company 2</option>
                    <option value="1">Company 3</option>
                    <option value="2">Company 2</option>
                  </Form.Select>
                </div>
              </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to remove this budget from list
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpage}
        />
      </div>
    </Layout>
  );
}
