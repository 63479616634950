import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { UpdateBudget, addBudget, getBudgetDetails, getcompny } from "../redux/actions/AdminActions";
import { toast } from "react-toastify";
import Budget from "./Budget";

const validationSchema = Yup.object().shape({
  budget_name: Yup.mixed().required(
    <span className="error-message">Required</span>
  ),
  budget_type: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  comments: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  company_name: Yup.string().required(
    <span className="error-message">Required</span>
  )
});

export default function AddBudget() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [load, setLoad] = useState(true);
  const [DropdowncompanyList, setDropdowncompanyList] = useState([]);


  const [initialvalues, setInitialValues] = useState({
    budget_name: "",
    budget_type: "",
    comments: "",
    company_name: ""
  });

  const fetchbudgetDetails = async () => {
    try {
      const response = await dispatch(getBudgetDetails(id));
      const budget = response.payload.data.data;
      setInitialValues({
        budget_name: budget?.budget_name,
        budget_type:  budget?.budget_type,
        comments:  budget?.comments,
        company_name: budget?.company_id?._id,
      });
    } catch (error) {
      console.log("Error fetching company details:", error);
    }
    finally{
      setLoad(false)
    }
  };

  useEffect(() => {
    if(id){
      fetchbudgetDetails();
     }else{
       setLoad(false)
     }
   }, [id]);


   useEffect(() => {
    dispatch(getcompny())
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setDropdowncompanyList(res?.payload?.data?.data?.totalCompanies);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleAddBudget = async (values) => {
    try {
      let data;
      const payload = {
        budget_name: values?.budget_name,
        budget_type:  values?.budget_type,
        comments:  values?.comments,
        company_id: values?.company_name,
      }
      if (id) {
        data = await dispatch(
          UpdateBudget({
            budgetId:id,
            budget_name: values?.budget_name,
            budget_type:  values?.budget_type,
            comments:  values?.comments,
            company_id: values?.company_name,
          })
        );
      } else {
        data = await dispatch(addBudget(payload));
      }
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        navigate("/budget");
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>{id ? "Edit Budget" : "Add Budget"}</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/budget">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Col lg={8}>
            <div className="customer-form-new">
              <div className="customer-form-inner">
                  { !load &&  <Formik
                  initialValues={initialvalues}
                  validationSchema={validationSchema}
                  onSubmit={handleAddBudget}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={12}>
                          <div className="admin-box-select">
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Budget Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Budget Name..."
                                  name="budget_name"
                                  onChange={handleChange}
                                  value={values?.budget_name}
                                />
                                <ErrorMessage name="budget_name" component="div"/>
                              </Form.Group>
                            </Col>
                          </div>
                        </Col>
                        <Col lg={12}>
                        <Form.Label>Budget Type</Form.Label>
                          <Form.Select
                            className="form-select"
                            aria-label="Default select example"
                            name="budget_type"
                            onChange={handleChange}
                            value={values?.budget_type}
                          >
                            <option>Select</option>
                            <option value="0">Income</option>
                            <option value="1">Expense</option>
                          </Form.Select>
                          <ErrorMessage name="budget_type" component="div"/>

                        </Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Company</Form.Label>
                            <Form.Select
                              className="form-select"
                              aria-label="Default select example"
                              name="company_name"
                              onChange={handleChange}
                              value={values?.company_name}
                            >
                              <option>Select</option>
                              {DropdowncompanyList?.map((res) => {
                                return (
                                  <option value={res?._id}>
                                    {res?.company_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <ErrorMessage name="company_name" component="div"/>
                          </Form.Group>
                        
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              Rows="4"
                              placeholder="Type Here"
                              name="comments"
                              onChange={handleChange}
                              value={values?.comments}
                            />
                            <ErrorMessage name="comments" component="div"/>
                          </Form.Group>
                        </Col>

                        <Col
                          lg={12}
                          className="d-flex justify-content-center mt-3"
                        >
                          <button type="submit" className="add-btn">
                           {id ? "Update" : "Add"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik> }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
