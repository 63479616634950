import React from "react";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";


const validationSchema = yup.object().shape({
  oldpassword: yup.string().required("Required"),
  newpassword: yup.string().required("Required"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("newpassword"), null], "Passwords must match")
    .required("Required"), 
});

export default function ChangePassword() {

  const initialvalues  = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: ""
  }
  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <h2>Change Password</h2>
          </div>
          <hr />
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4">
            <Col lg="7" md="auto">
              <Card className="border- p-5 rounded-card customer-form-new">
                <h2 className="text-center">Change Password</h2>
                 <Formik initialValues={initialvalues}
                 validationSchema={validationSchema}
                 onSubmit={(values) => {
                  console.log(values,"Input field values");
                 }}>
                  {({values,handleSubmit,handleChange}) => (           
                <Form className="change-password-form px-5" onSubmit={handleSubmit}>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Old Password"
                        name="oldpassword"
                        onChange={handleChange}
                        value={values?.oldpassword}
                      />
                      <ErrorMessage name="oldpassword" component="div" className="text-danger"/>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        name="newpassword"
                        onChange={handleChange}
                        value={values?.newpassword}
                      />
                      <ErrorMessage name="newpassword" component="div" className="text-danger"/>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        name="confirmpassword"
                        onChange={handleChange}
                        value={values?.confirmpassword}
                      />
                      <ErrorMessage name="confirmpassword" component="div" className="text-danger"/>
                    </Form.Group>
                  </Row>
                  <Form.Group
                    className="mt-4  mb-5 model-btn text-center"
                    controlId="formGridAddress2"
                  >
                    <div class="cmn-btn">
                      <button type="submit">SAVE</button>
                    </div>
                  </Form.Group>
                </Form>
                   )}
                   </Formik>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
