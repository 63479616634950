import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";

import Dashboard from "./pages/Dashboard";

import CustomerProfile from "./pages/CustomerProfile";
import ChangePassword from "./pages/ChangePassword";
import Analytics from "./pages/Analytics";
import Transaction from "./pages/Transactions";
import BankAccount from "./pages/BankAccount";
import AddBankDetails from "./pages/AddBankDetails";
import Companies from "./pages/Company";
import AddCompanydetails from "./pages/AddCompanyDetails";
import Budget from "./pages/Budget";
import AddBudget from "./pages/AddBudget";
import ResetPassword from "./pages/ResetPassword";

import AddTransaction from "./pages/AddTransaction";
import AddUser from "./pages/AddUser";
import UserActive from "./pages/Users";
import AllUsers from "./pages/Users";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/budget" element={<Budget />} />
          <Route path="/edit-budget/:id" element={<AddBudget />} />
          <Route path="/addbudget" element={<AddBudget />} />

          <Route path="/Company" element={<Companies />} />
          <Route path="/edit-company/:id" element={<AddCompanydetails />} />
          <Route path="/addCompanydetails" element={<AddCompanydetails />} />
          {/* <Route path="/addCompanydetails" element={<AddCompanydetails />} /> */}

          <Route path="/bankAccount" element={<BankAccount />} />
          <Route path="/edit-bank/:id" element={<AddBankDetails />}/>
          <Route path="/Addbankdetails" element={<AddBankDetails />} />

          <Route path="/Transaction" element={<Transaction />} />
          <Route path="/add-transaction" element={<AddTransaction />} />

          <Route path="/" element={<Login />} />
          <Route path="/Otp" element={<LoginOtp />} />
          <Route path="/Resetpassword" element={<ResetPassword />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/UserManagement" element={<UserManagement />} />
          <Route path="/Dashboard" element={<Dashboard />} />

          <Route path="/ChangePassword" element={<ChangePassword />} />

          <Route path="/Analytics" element={<Analytics />}/>
          <Route path="/users" element={<AllUsers/>} />
          <Route path="/edit-user/:id" element={<AddUser/>}/>
          <Route path="/add-user" element={<AddUser/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
