import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../services/AdminApi";


// =========== get All Companies =============

export const getAllCompanies = createAsyncThunk(
    "getAllCompanies",
    async (details) => {
      let url = `/getCompanies?page=${details?.page}&limit=${details?.limit}`;
  
      if (details.search) {
        url += `&name=${details.search}`;
      }
  
      const data = await AdminAPI.get(url);

      return data;
    }
  );

  // ================ Add Companies ==============

  export const AddCompany = createAsyncThunk("AddCompany", async (details) => {
    const data = await AdminAPI.post(`/createCompany`, details);
    return data;
  });

  // ============= Update Companies ===============

  export const UpdateCompany = createAsyncThunk("UpdateCompany", async (details) => {
    const data = await AdminAPI.put(`/updateCompany`, details);
    return data;
  });

   // ============= get Company details by id ===============

   export const getCompanyDetails = createAsyncThunk("getCompanyDetails", async (id) => {
    const data = await AdminAPI.get(`/getCompanyById?companyId=${id}`,);
    return data;
  });


  // ======= Delete Company ===========

  export const DeleteCompany = createAsyncThunk(
    "DeleteCompany",
    async(details) => {
        const data = await AdminAPI.delete(`/deleteCompany?companyId=${details?.companyId}`);
  
       return data;
    } 
  );




  // =========== get All Banks =============

export const getAllBanks = createAsyncThunk(
  "getAllBanks",
  async (details) => {
    let url = `/getBanks?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);

    return data;
  }
);

// ================ Add Bank ==============

export const addBank = createAsyncThunk("addBank", async (details) => {
  const data = await AdminAPI.post(`/createBank`, details);
  return data;
});

// ============= Update Bank ===============

export const UpdateBank = createAsyncThunk("UpdateBank", async (details) => {
  const data = await AdminAPI.put(`/updateBank`, details);
  return data;
});

 // ============= get Company details by id ===============

 export const getBankDetails = createAsyncThunk("getBankDetails", async (id) => {
  const data = await AdminAPI.get(`/getBankById?bankId=${id}`);
  return data;
});


// ======= Delete Company ===========

export const DeleteBank = createAsyncThunk(
  "DeleteBank",
  async(details) => {
      const data = await AdminAPI.delete(`/deleteBank?bankId=${details?.bankId}`);

     return data;
  } 
);



 // =========== get Budget =============

 export const getBudget = createAsyncThunk(
  "getBudget",
  async (details) => {
    let url = `/getBudgetList?page=${details?.page}&limit=${details?.limit}&type=${details?.type}&company_id=${details?.company_id}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);

    return data;
  }
);

// ================ Add Budget ==============

export const addBudget = createAsyncThunk("addBudget", async (details) => {
  const data = await AdminAPI.post(`/createBudget`, details);
  return data;
});

// ============= Update Budget ===============

export const UpdateBudget = createAsyncThunk("UpdateBudget", async (details) => {
  const data = await AdminAPI.put(`/updateBudget`, details);
  return data;
});

 // ============= get Budgets details by id ===============

 export const getBudgetDetails = createAsyncThunk("getBudgetDetails", async (id) => {
  const data = await AdminAPI.get(`/getBudgetById?budgetId=${id}`);
  return data;
});


// ======= Delete Budget ===========

export const DeleteBudget = createAsyncThunk(
  "DeleteBudget",
  async(details) => {
      const data = await AdminAPI.delete(`/deleteBudget?budgetId=${details?.budgetId}`);

     return data;
  } 
);


// ============ get companylist when add bank ================
export const getcompny = createAsyncThunk(
  "getcompny",
  async (details) => {
    const data = await AdminAPI.get(`/getCompanies`);

    return data;
  }
);



// ============ get compay list by filter in Budget tab ================
export const CompanyFilter = createAsyncThunk(
  "CompanyFilter",
  async (details) => {
    const data = await AdminAPI.get(`/getCompanies`);

    return data;
  }
);
  
// =========== Add Users ============
export const Addusers = createAsyncThunk("Addusers", async (details) => {
  const data = await AdminAPI.post(`/addUser`, details);
  return data;
});


 // =========== get All Users  =============

 export const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async (details) => {
    let url = `/getUsers?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);

    return data;
  }
);


// ======= Delete User ===========

export const DeleteUser = createAsyncThunk(
  "DeleteUser",
  async(details) => {
      const data = await AdminAPI.delete(`/delUser?userId=${details?.userId}`);

     return data;
  } 
);

 // ============ Update User ==============

export const UpdateUser = createAsyncThunk("UpdateUser", async (details) => {
  const data = await AdminAPI.put(`/updateUser`, details);
  return data;
});

// ============= get User details by id ===============

export const getUserdetails = createAsyncThunk("getUserdetails", async (id) => {
  const data = await AdminAPI.get(`/getUserById?userId=${id}`);
  return data;
});

// =========== Update User Password ==========

export const UpdateUserPassword = createAsyncThunk("UpdateUserPassword", async (details) => {
  const data = await AdminAPI.put(`/updatePassword`, details);
  return data;
});
