import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../services/AdminApi";




const login = createAsyncThunk(
    "login", 
    async(details) => {
        const { data } = await AdminAPI.post('/auth/login',details);
        console.log(data,"datadata")

        sessionStorage.setItem("token", data?.data?.token)
        sessionStorage.setItem("is_admin", data?.data?.is_admin)
        console.log(data?.data?.is_admin);

        return data;
    }
);

export { login };