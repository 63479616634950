import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  AddCompany,
  UpdateCompany,
  getCompanyDetails,
} from "../redux/actions/AdminActions";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  companyLogo: Yup.mixed().required(
    <span className="error-message">Required</span>
  ),
  companyName: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  cinNumber: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  gstNumber: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  panNumber: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  tanNumber: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  regDate: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  notes: Yup.string().required("Required"),
});

export default function AddCompanydetails() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [load, setLoad] = useState(true);
  const [preview, setPreview] = useState(null);

  const [initialvalues, setInitialValues] = useState({
    companyLogo: null,
    companyName: "",
    cinNumber: "",
    gstNumber: "",
    panNumber: "",
    tanNumber: "",
    regDate: "",
    notes: "",
  });
  console.log(initialvalues, "setInitialValues");

  const fetchCompanyDetails = async () => {
    try {
      const response = await dispatch(getCompanyDetails(id));
      const company = response.payload.data.data;
      console.log(company, "davedetails");
      setInitialValues({
        companyLogo: company?.company_logo,
        companyName: company?.company_name,
        cinNumber: company.cin_number,
        gstNumber: company.gst_number,
        panNumber: company.pan_number,
        tanNumber: company.tan_number,
        regDate: new Date(company.reg_date)
          .toLocaleDateString("en-CA")
          .replace(/-/g, "/"),
        notes: company.comments,
      });
     setPreview(`${url}${company?.company_logo}`)
    } catch (error) {
      console.log("Error fetching company details:", error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCompanyDetails();
    } else {
      setLoad(false);
    }
  }, [id]);

  const handleAddCompany = async (values) => {
    const formData = new FormData();
    formData.append("company_logo", values?.companyLogo);
    formData.append("company_name", values.companyName);
    formData.append("cin_number", values.cinNumber);
    formData.append("gst_number", values.gstNumber);
    formData.append("pan_number", values.panNumber);
    formData.append("tan_number", values.tanNumber);
    formData.append("reg_date", values.regDate);
    formData.append("comments", values.notes);
    if (id) {
      formData.append("companyId", id);
    }
    try {
      let data;
      if (id) {
        data = await dispatch(UpdateCompany(formData));
      } else {
        data = await dispatch(AddCompany(formData));
      }
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        navigate("/Company");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>{id ? "Edit Comapny Details" : "Add Comapny Details"}</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Company">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Col lg={8}>
            <div className="customer-form-new">
              <div className="customer-form-inner">
                {!load && (
                  <Formik
                    initialValues={initialvalues}
                    validationSchema={validationSchema}
                    onSubmit={handleAddCompany}
                  >
                    {({
                      values,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <div className="admin-box-select">
                              <Col lg={12}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Company Logo</Form.Label>
                                  <Form.Control
                                    type="file"
                                    accept="image/*"
                                    name="companyLogo"
                                    onChange={(event) => {
                                      const file = event.currentTarget.files[0];
                                      console.log(file);
                                      if (file) {
                                        const imageUrl =
                                          URL.createObjectURL(file);
                                        setPreview(imageUrl);
                                        setFieldValue("companyLogo", file);
                                      }
                                    }}
                                  />
                                  {preview && (
                                    <div className="mt-2">
                                      <img
                                        src={preview}
                                        alt="Preview"
                                        style={{
                                          maxWidth: "100px",
                                          height: "100px",
                                        }}
                                      />
                                    </div>
                                  )}
                                  <ErrorMessage
                                    name="companyLogo"
                                    component="div"
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={12}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Company name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Company Name..."
                                    name="companyName"
                                    value={values?.companyName}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    name="companyName"
                                    component="div"
                                  />
                                </Form.Group>
                              </Col>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Cin Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Cin Numaber..."
                                name="cinNumber"
                                value={values?.cinNumber}
                                onChange={handleChange}
                              />
                              <ErrorMessage name="cinNumber" component="div" />
                            </Form.Group>
                          </Col>

                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>GST Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="GST Number..."
                                name="gstNumber"
                                value={values?.gstNumber}
                                onChange={handleChange}
                              />
                              <ErrorMessage name="gstNumber" component="div" />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>PAN Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="PAN Number..."
                                name="panNumber"
                                value={values?.panNumber}
                                onChange={handleChange}
                              />
                              <ErrorMessage name="panNumber" component="div" />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>TAN Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="TAN Number..."
                                name="tanNumber"
                                value={values?.tanNumber}
                                onChange={handleChange}
                              />
                              <ErrorMessage name="tanNumber" component="div" />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>REG. Date</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="yyyy/mm/dd"
                                name="regDate"
                                value={values?.regDate}
                                onChange={handleChange}
                              />
                              <ErrorMessage name="regDate" component="div" />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Notes</Form.Label>
                              <Form.Control
                                as="textarea"
                                Rows="3"
                                placeholder="Type Here"
                                name="notes"
                                value={values?.notes}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="notes"
                                className="error-message"
                                component="div"
                              />
                            </Form.Group>
                          </Col>

                          <Col
                            lg={12}
                            className="d-flex justify-content-center mt-3"
                          >
                            <button type="submit" className="add-btn">
                              {id ? "Update" : "Add"}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
