import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik, ErrorMessage,Field} from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  bankAccount: Yup.string()
    // .oneOf(['HDFC(Codobux)', 'HDFC(Certiwise)'], 'Invalid Bank Account')
    .required(<span className="error-message">Required</span>),

  date: Yup.date()
    .required(<span className="error-message">Required</span>)
    .nullable(),

  budgetType: Yup.string()
    // .oneOf(['OfficeRent(Codobux,Expense)', 'OfficeRent(Certiwise,Income)'], 'Invalid Budget Type')
    .required(<span className="error-message">Required</span>),

  amountType: Yup.string()
    // .oneOf(['Debit', 'Credit'], 'Invalid Amount Type')
    .required(<span className="error-message">Required</span>),

  markAsCount: Yup.boolean().oneOf([true], <span className="error-message">Required</span>),
});

export default function AddTransaction() {
  const initialvalues = {
    bankAccount: "",
    date: "",
    budgetType: "",
    amountType: "",
    markAsCount: false,
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Add Transactions</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Transaction">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Col lg={8}>
            <div className="customer-form-new">
              <div className="customer-form-inner">
                <Formik
                  initialValues={initialvalues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    console.log(values);
                  }}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={12}>
                          <div>
                            <Form.Group className="mb-3">
                              <Form.Label>Bank Account</Form.Label>
                              <Form.Select
                                className="form-select"
                                aria-label="Default select example"
                                name="bankAccount"
                                onChange={handleChange}
                                value={values?.bankAccount}
                              >
                                <option>Select</option>
                                <option>HDFC(Codobux)</option>
                                <option>HDFC(Certiwise)</option>
                              </Form.Select>
                              <ErrorMessage
                                name="bankAccount"
                                component="div"
                              />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="7Aug,2024"
                              name="date"
                              value={values?.date}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="date" component="div" />
                          </Form.Group>
                        </Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Budeget Type</Form.Label>
                          <Form.Select
                            className="form-select"
                            aria-label="Default select example"
                            name="budgetType"
                            onChange={handleChange}
                            value={values?.budgetType}
                          >
                            <option>Select</option>
                            <option>OfficeRent(Codobux,Expense)</option>
                            <option>OfficeRent(Certiwise,Income)</option>
                          </Form.Select>
                          <ErrorMessage name="budgetType" component="div" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Amount Type</Form.Label>
                          <Form.Select
                            className="form-select"
                            aria-label="Default select example"
                            name="amountType"
                            onChange={handleChange}
                            value={values?.amountType}
                          >
                            <option>Select</option>
                            <option>Debit</option>
                            <option>Credit</option>
                          </Form.Select>
                          <ErrorMessage name="amountType" component="div" />
                        </Form.Group>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Notes(Optional)</Form.Label>
                            <Form.Control type="text" placeholder="Type..." />
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Clint Name(Optional)</Form.Label>
                            <Form.Control type="text" placeholder="Type..." />
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Attachment(Optional)</Form.Label>
                            <Form.Control type="file" placeholder="Type..." />
                          </Form.Group>
                        </Col>
                        <div>
                          <Field type="checkbox" name="markAsCount" />
                          <label htmlFor="markAsCount">:Mark as count</label>
                          <ErrorMessage name="markAsCount" component="div" />
                        </div>

                        <Col
                          lg={12}
                          className="d-flex justify-content-center mt-3"
                        >
                          <button type="submit" className="add-btn">
                            Add
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
