import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Addusers, UpdateUser, getUserdetails } from "../redux/actions/AdminActions";

const validationSchema = Yup.object({
  fullName: Yup.string().required(
    <span className="error-message">Required</span>
  ),
  email: Yup.string()
    .email(<span className="error-message">Invalid email address</span>)
    .required(<span className="error-message">Required</span>),
  password: Yup.string()
    .min(
      6,
      <span className="error-message">
        Password must be atleast 6 Characters
      </span>
    )
    .required(<span className="error-message">Required</span>),
  permission: Yup.string()
    //   .oneOf(['Super Admin(Add,edit,view)', 'User(View only)'], 'Invalid permission type')
    .required(<span className="error-message">Required</span>),
});

export default function AddUser() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const [load, setLoad] = useState(true);


  const [initialvalues, setInitialValues] = useState({
    fullName: "",
    email: "",
    password: "",
    permission: ""
  });


  const fetchUserDetails = async () => {
    try {
      const response = await dispatch(getUserdetails(id));
      const UserDetail = response.payload.data.data;
      console.log(UserDetail);
      setInitialValues({
        fullName: UserDetail?.full_name,
        email:  UserDetail?.email,
        // password:  UserDetail?.password,
        permission: UserDetail?.is_admin,
      });
    } catch (error) {
      console.log("Error fetching company details:", error);
    }
    finally{
      setLoad(false)
    }
  };

  useEffect(() => {
    if(id){
      fetchUserDetails();
     }else{
       setLoad(false)
     }
   }, [id]);




  const handleAddUser = async (values) => {
    try {
      let data;
      const payload = {
        full_name: values?.fullName,
        email: values?.email,
        password: values?.password,
        is_admin: values?.permission,
      }
      if (id) {
        data = await dispatch(
          UpdateUser({
            userId:id,
            full_name: values?.fullName,
            email:  values?.email,
            password:  values?.password,
            is_admin: values?.permission,
          })
        );
      } else {
        data = await dispatch(Addusers(payload));
      }
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        navigate("/users");
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>{id ? "Edit User Details" : "Add User Details"}</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/users">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Col lg={8}>
            <div className="customer-form-new">
              <div className="customer-form-inner">
               {!load && <Formik
                  initialValues={initialvalues}
                  validationSchema={validationSchema}
                  onSubmit={handleAddUser}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={12}>
                          <div className="admin-box-select">
                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name..."
                                  name="fullName"
                                  value={values?.fullName}
                                  onChange={handleChange}
                                />
                                <ErrorMessage name="fullName" component="div" />
                              </Form.Group>
                            </Col>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Email..."
                              name="email"
                              value={values?.email}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="email" component="div" />
                          </Form.Group>
                        </Col>

                       {!id && (
                       <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Password.."
                              name="password"
                              value={values?.password}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="password" component="div" />
                          </Form.Group>
                        </Col>
                        
                        )}
                        <Form.Group className="mb-3">
                          <Form.Label>Permission</Form.Label>
                          <Form.Select
                            className="form-select"
                            aria-label="Default select example"
                            name="permission"
                            value={values?.permission}
                            onChange={handleChange}
                          >
                            <option>Select</option>
                            <option value="1">Super Admin(Add,edit,view)</option>
                            <option value="0">User(View only)</option>
                          </Form.Select>
                          <ErrorMessage name="permission" component="div" />
                        </Form.Group>

                        <Col
                          lg={12}
                          className="d-flex justify-content-center mt-3"
                        >
                          <button type="submit" className="add-btn">
                            {id ? "Update" : "Add"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik> }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
