import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import Users from "../Components/Layout/elements/ChartUsers";
import Orders from "../Components/Layout/elements/ChartOrders";

export default function Analytics() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Analytics</h2>
        </div>
        <hr />
        {/* <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Keyword Search.." />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="#">DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr /> */}
      </div>
      <Container fluid>
        
        <Row className="dash-bottom mb-4 mt-5">
          <Col xxl={6} xl={6} lg={6} className="mb-4">
            <div className="dash-graph">
              <h6>Dummy</h6>
              <Users />
            </div>
          </Col>
          {/* <Col xxl={4} xl={4} lg={4}>
                  <div className="dashbox-side">
                    <h2>MEMBERS </h2>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL MEMBERS </h6>
                      <h4>10,292</h4>
                      <p>10% increase from last month</p>
                    </div>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL CLUBS </h6>
                      <h4>761</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
          <Col xxl={6} xl={6} lg={6}>
            <div className="dash-graph">
              <h6>Website Traffic</h6>
              <Orders />
            </div>
          </Col>
          <Col xxl={12} xl={12} lg={12}>
            <div className="dash-graph p-5">
              <h4 className="text-center">Google Analytics: Yet to be decided</h4>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
